<script>
import SectionBlock from '../general/SectionBlock'
import SelectField from '../general/SelectField'
import InputField from '@/components/general/InputField'
import TimezonePt from '@/support/timezones/time_zones_pt.json'
import TimezoneEn from '@/support/timezones/time_zones_en.json'
import ConfirmationDeleteModal from './components/ConfirmationDeleteModal.vue'
import { mapActions } from 'vuex'
export default {
  components: { SectionBlock, SelectField, InputField, ConfirmationDeleteModal },
  name: 'SettingsForm',
  data () {
    return {
      formData: {
        originalTimezone: null,
        timezone: null,
        language: null,
        languagePlatform: null,
        originalLanguagePlatform: null,
        languageContents: null,
        userEmail: null,
        company: null
      },
      formPassword: {
        oldPass: '',
        newPass: '',
        repeatPass: ''
      },
      formModal: {
        email: '',
        password: ''
      },
      showModal: false,
      inputLanguagePlatformChanged: false,
      inputTimezoneChanged: false,
      validationPassword: false,
      validationSpan: []
    }
  },
  created () {
    this.getUserProfile()
  },
  computed: {
    getUser () {
      return this.$store.getters.getUser
    },
    successMessages () {
      return {
        en: 'Language information successfully saved!',
        es: '¡Información lingüística guardada correctamente!',
        pt: 'Informação de idioma salva com sucesso!'
      }
    }
  },
  methods: {
    ...mapActions(['setLanguage']),
    changeLanguage (language) {
      this.setLanguage(language)
    },
    getUserProfile () {
      this.$store.dispatch('attemptGetUserProfile').then((data) => {
        this.formData.originalTimezone = data.settings.timezone
        this.formData.timezone = data.settings.timezone
        this.formData.originalLanguagePlatform = this.handleLanguage(data.settings.language)
        this.formData.languagePlatform = this.handleLanguage(data.settings.language)
        this.formData.language = data.settings.language
        this.formData.userEmail = data.personalDetails.email
        this.formData.company = data.settings.companies !== undefined
      })
    },
    saveUpdateSeetings (opt) {
      let data = null

      if (opt === 'language') {
        data = { filter: 'settings', settings: { language: this.verifyLanguage(this.formData.languagePlatform) } }
        const companies = this.$store.getters.getUser.companies
        if (companies && companies.length) {
          data.settings.companies = companies.map(c => ({
            id: c.id,
            language: data.settings.language
          }))
        }
      }
      if (opt === 'timezone') data = { filter: 'settings', settings: { timezone: this.formData.timezone } }

      this.$store.dispatch('attemptUpdateUserProfile', data)
        .then(() => {
          const storageObject = this.getUser

          if (opt === 'language') {
            storageObject.language = this.formData.languagePlatform
            this.changeLanguage(this.formData.languagePlatform)
          }

          this.$store.dispatch('attemptUpdateUserGlobalInfo', { ...storageObject }).then(() => {
            this.inputLanguagePlatformChanged = false
            this.inputTimezoneChanged = false
            this.validationPassword = false
            this.getUserProfile()
            this.$store.dispatch('attemptSetFeedbackMsg', {
              type: 'success',
              title: opt === 'language' ? this.successMessages[this.formData.languagePlatform] : this.$t('settings:timezone.sucess')
            })
          })
        })
    },
    handleChangeNewPassword () {
      this.validationSpan = []
      const oneDigit = /(?=.*\d)/.test(this.formPassword.newPass)
      if (oneDigit === false) this.validationSpan.push('oneDigit')
      const oneCharLower = /(?=.*[a-z])/.test(this.formPassword.newPass)
      if (oneCharLower === false) this.validationSpan.push('oneCharLower')
      const oneCharUper = /(?=.*[A-Z])/.test(this.formPassword.newPass)
      if (oneCharUper === false) this.validationSpan.push('oneCharUpper')
      const oneEspecialChar = /(?=.*[$*@!#%&()^~{}])/.test(this.formPassword.newPass)
      if (oneEspecialChar === false) this.validationSpan.push('oneEspecialChar')
      const minEightChar = /[0-9a-zA-Z$*@!#%&()^~{}]{8,}/.test(this.formPassword.newPass)
      if (minEightChar === false) this.validationSpan.push('minEightChar')
    },
    handleChangePassword () {
      if (this.formPassword.oldPass !== '' && this.formPassword.newPass !== '' && this.formPassword.repeatPass !== '' && this.validationSpan.length === 0) {
        this.validationPassword = true
      } else {
        this.validationPassword = false
      }
    },
    handleChangeLanguage () {
      if (this.formData.originalLanguagePlatform !== this.formData.languagePlatform) {
        this.inputLanguagePlatformChanged = true
      } else {
        this.inputLanguagePlatformChanged = false
      }
    },
    handleChangeTimezone () {
      if (this.formData.originalTimezone !== this.formData.timezone) {
        this.inputTimezoneChanged = true
      } else {
        this.inputTimezoneChanged = false
      }
    },
    verifyLanguage (lg) {
      if (lg === this.$t('advanced.filter:active.filter.languages.pt-BR')) {
        this.formData.languagePlatform = 'pt'
        return this.formData.languagePlatform
      }
      if (lg === this.$t('advanced.filter:active.filter.languages.en')) {
        this.formData.languagePlatform = 'en'
        return this.formData.languagePlatform
      }
    },
    handleLanguage (lg) {
      if (lg === 'pt') {
        this.formData.languagePlatform = this.$t('advanced.filter:active.filter.languages.pt-BR')
        return this.formData.languagePlatform
      }
      if (lg === 'en') {
        this.formData.languagePlatform = this.$t('advanced.filter:active.filter.languages.en')
        return this.formData.languagePlatform
      }
    },
    showConfirmationModal () {
      this.showModal = !this.showModal
    },
    getTimezones () {
      const timezonesData = []
      const timezones = this.formData.language === 'pt' ? TimezonePt : TimezoneEn
      for (const iterator of timezones) {
        timezonesData.push(iterator.timeZone)
      }
      return timezonesData.sort()
    },
    submitPassword () {
      if (this.formPassword.newPass !== this.formPassword.repeatPass) {
        this.$store.dispatch('attemptSetFeedbackMsg', {
          type: 'error',
          title: this.$t('global:validation.different.password')
        })
      } else {
        const data = {
          email: this.formData.userEmail,
          password: this.formPassword.oldPass,
          newPassword: this.formPassword.newPass,
          template: `candidate:password_changed-${this.formData.language}`,
          subject: this.$t('settings.account.labels:submit.change.password.done')
        }
        this.$store.dispatch('attemptUpdateProfilePassword', data).then(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'success',
            title: this.$t('settings.account.labels:submit.change.password.done')
          })
          this.formPassword.oldPass = null
          this.formPassword.newPass = null
          this.formPassword.repeatPass = null
        }).catch((err) => {
          if (err.response.status === 404) {
            this.$store.dispatch('attemptSetFeedbackMsg', {
              type: 'error',
              title: this.$t('global:validation.incorrect')
            })
          }
        })
      }
    },
    deleteAccount (val) {
      const data = {
        lang: this.formData.language,
        email: val.email,
        password: val.password
      }

      this.$store.dispatch('attemptPostDeleteAccount', data).then(() => {
        this.clearStorageAndLogout(this.baseUrl)
      }).catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'error',
            title: this.$t('global:validation.incorrect')
          })
        }
      })
    }
  },
  watch: {
    formPassword: {
      deep: true,
      handler () {
        this.handleChangePassword()
      }
    }
  }
}
</script>
<template>
  <div class="settings-form--container">
    <section-block title="settings.account.labels:change.password.title">
      <input-field v-model="formPassword.oldPass" type="password" outlined dense
        :placeholder="$t('settings.account.labels:change.password.actual.old.pass')" append-icon="mdi-eye"></input-field>
      <span class="settings-form--container-password-span" v-if="validationSpan.length">
        <ul>
          <p v-html="$t('settings.account.labels:submit.change.password.span.title')"></p>
          <li :style="{ color: validationSpan.includes('minEightChar') ? '#e08883' : '#5edf7e' }"><v-icon small
              :color="validationSpan.includes('minEightChar') ? '#e08883' : '#5edf7e'">mdi-close-circle</v-icon> {{
                $t('settings.account.labels:submit.change.password.span.eigth.char')
              }}</li>
          <li :style="{ color: validationSpan.includes('oneCharUpper') ? '#e08883' : '#5edf7e' }"><v-icon small
              :color="validationSpan.includes('oneCharUpper') ? '#e08883' : '#5edf7e'">mdi-checkbox-marked-circle</v-icon>
            {{ $t('settings.account.labels:submit.change.password.span.one.upper.char') }}</li>
          <li :style="{ color: validationSpan.includes('oneCharLower') ? '#e08883' : '#5edf7e' }"><v-icon small
              :color="validationSpan.includes('oneCharLower') ? '#e08883' : '#5edf7e'">mdi-checkbox-marked-circle</v-icon>
            {{ $t('settings.account.labels:submit.change.password.span.one.lower.char') }}</li>
          <li :style="{ color: validationSpan.includes('oneDigit') ? '#e08883' : '#5edf7e' }"><v-icon small
              :color="validationSpan.includes('oneDigit') ? '#e08883' : '#5edf7e'">mdi-checkbox-marked-circle</v-icon>
            {{ $t('settings.account.labels:submit.change.password.span.one.number') }}</li>
          <li :style="{ color: validationSpan.includes('oneEspecialChar') ? '#e08883' : '#5edf7e' }"><v-icon small
              :color="validationSpan.includes('oneEspecialChar') ? '#e08883' : '#5edf7e'">mdi-checkbox-marked-circle</v-icon>
            {{ $t('settings.account.labels:submit.change.password.span.special.char') }}</li>
        </ul>
      </span>
      <input-field @input="handleChangeNewPassword" v-model="formPassword.newPass" type="password" outlined dense
        :placeholder="$t('settings.account.labels:change.password.actual.new.pass')" append-icon="mdi-eye"></input-field>
      <input-field v-model="formPassword.repeatPass" type="password" outlined dense
        :placeholder="$t('settings.account.labels:change.password.actual.confirm.new.pass')"
        append-icon="mdi-eye"></input-field>

      <div class="settings-form--button">
        <v-btn class="btn transform-unset" :color="getPrimaryColor" :disabled="!validationPassword"
          @click="submitPassword" :dark="validationPassword">{{
            $t('settings.account.labels:submit.change.password')
          }}</v-btn>
      </div>
    </section-block>

    <section-block title="account.form:languages.title">
      <div class="settings-laguage">
        <div>
          <h4>{{ $t('settings:language.subtitle.platform') }}</h4>
          <p class="section-description">{{ $t('settings:language.info') }}</p>
          <select-field v-model="formData.languagePlatform" @change="handleChangeLanguage" outlined small
            :items="[$t('advanced.filter:active.filter.languages.pt-BR'), $t('advanced.filter:active.filter.languages.en')]"></select-field>
          <div class="settings-form--button">
            <v-btn class="btn transform-unset mb-6" :color="getPrimaryColor" :disabled="!inputLanguagePlatformChanged"
              :dark="inputLanguagePlatformChanged" @click="saveUpdateSeetings('language')">{{ $t('global:save.changes')
              }}</v-btn>
          </div>
        </div>

        <div v-if="false">
          <h4>{{ $t('settings:language.subtitle.contents') }}</h4>
          <p class="section-description">{{ $t('settings:language.contents.info') }}</p>
          <select-field v-model="formaData.languageContents" multiple outlined small
            :items="[$t('advanced.filter:active.filter.languages.pt-BR'), $t('advanced.filter:active.filter.languages.en'), $t('advanced.filter:active.filter.languages.es')]"></select-field>
          <v-btn class="btn transform-unset mb-6" :color="getPrimaryColor" dark>{{ $t('global:save.changes') }}</v-btn>
        </div>
      </div>
    </section-block>
    <section-block title="settings:.timezone.title">
      <div class="notification-content">
        <select-field v-model="formData.timezone" @change="handleChangeTimezone" outlined small
          :items="getTimezones()"></select-field>

        <div class="settings-form--button">
          <v-btn class="btn transform-unset" :color="getPrimaryColor" :disabled="!inputTimezoneChanged"
            :dark="inputTimezoneChanged" @click="saveUpdateSeetings('timezone')">{{ $t('global:save.changes') }}</v-btn>
        </div>
      </div>
    </section-block>
    <section-block title="settings:profile.subtitle.privacy" v-if="false">
      <div class="privacy-content">
        <b><v-switch inset true-icon="mdi-content-save-outline" :label="$t('settings.profile.labels:private')"
            @change="val => $emit('change', val)" :color="getPrimaryColor"></v-switch></b>
        <p class="section-description">{{ $t('settings.profile.labels:private.warning') }}</p>
        <v-btn class="btn transform-unset" :color="getPrimaryColor" dark>{{ $t('global:save.changes') }}</v-btn>
      </div>
    </section-block>
    <section-block title="settings.account.labels:account.delete" v-if="!this.formData.company">
      <div class="notification-content">
        <p class="section-description" v-html="$t('settings.account.labels:account.removal.description')"></p>
        <div class="settings-form--button">
          <v-btn @click="showConfirmationModal" class="btn transform-unset" outlined :color="getPrimaryColor" dark>{{
            $t('settings.account.labels:account.delete')
          }}</v-btn>
        </div>
      </div>
    </section-block>
    <ConfirmationDeleteModal :show="showModal" :title="$t('settings.account.labels:account.delete.title.modal')"
      :description="$t('settings.account.labels:account.removal.description')" :confirmText="$t('global:delete')"
      :btnConfirmText="false" @confirm="val => deleteAccount(val)" :cancelText="$t('global:cancel')"
      @cancel="showConfirmationModal" />
  </div>
</template>
<style lang="scss">
.settings-form--container {
  margin-bottom: 40px;

  .settings-form--button {
    text-align: right;
  }

  .settings-form--container-password-span {
    position: relative;
    left: -20px;

    ul {
      margin-left: 0px;
      margin-bottom: 30px;

      .wrong {
        color: #e08883;
      }

      .correct {
        color: #5edf7e;
      }

      li {
        list-style: none;
      }
    }
  }

  .privacy-content {
    margin-top: -20px;
  }

  .settings-laguage {
    h4 {
      font-size: 14px;
    }

    margin-bottom: -20px;
  }

}
</style>
