<script>
import InputField from '@/components/general/InputField'
import { required, email } from 'vuelidate/lib/validators'
export default {
  components: { InputField },
  name: 'ConfirmationDeleteModal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    btnConfirmText: {
      type: Boolean,
      default: true
    },
    disableConfirmBtn: {
      type: Boolean,
      default: false
    },
    disableCancelBtn: {
      type: Boolean,
      default: false
    },
    confirmStyle: {
      type: String,
      default: 'normal'
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    confirmText: {
      type: String,
      default: ''
    },
    cancelText: {
      type: String,
      default: ''
    },
    maxWidth: {
      type: Number,
      default: 600
    },
    leftActions: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      formData: {
        email: '',
        password: ''
      }

    }
  },
  validations: {
    formData: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    }
  }
}
</script>
<template>
  <v-dialog
    :value="show"
    :key="show"
    content-class="dialog-confirm--modal"
    persistent
    :max-width="maxWidth"
  >
    <v-card>
      <v-card-title class="titleContent h7">
        {{ title }}
      </v-card-title>
      <span class="dialog-confirm--modal_subtitle subtitle--1" v-if="subtitle">{{ subtitle }}</span>
      <span class="top-right">
        <v-btn icon @click="$emit('cancel')">
          <v-icon :color="getPrimaryColor">mdi-close</v-icon>
        </v-btn>
      </span>
      <p class="text-left dialog-confirm--modal_description subtitle--1" v-html="description"></p>
      <input-field type="text" outlined dense v-model="formData.email"
        :placeholder="$t('settings.account.labels:account.delete.email')"
        :validation="$v.formData.email"
        append-icon="mdi-email"></input-field>
      <input-field type="password" outlined dense v-model="formData.password"
        :placeholder="$t('settings.account.labels:change.password.actual.new.pass')"
        :validation="$v.formData.password"
        append-icon="mdi-eye"></input-field>
      <div class="dialog-confirm--actions" :class="{'left-actions': leftActions}">
        <v-btn
          dark
          text
          :color="getPrimaryColor"
          @click="$emit('cancel')"
          class="text-secondary transform-unset mr-2"
          :disabled="disableCancelBtn"
        >
          {{ cancelText }}
        </v-btn>
        <v-btn
          class="transform-unset"
          :disabled="disableConfirmBtn"
          :text="btnConfirmText"
          :dark="!btnConfirmText && !disableConfirmBtn"
          :color="confirmStyle === 'warning' ? '#E83651' : getPrimaryColor"
          @click="$emit('confirm', formData)"
        >
          {{ confirmText }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.dialog-confirm--modal {
  font-family: $lato;
  text-align: left;
  .v-sheet {
    padding: 24px;
  }
  .v-card__text {
    text-align: left;
    font-family: $lato;
    font-size: 16px;
    line-height: 19px;
    margin-top: 20px;
  }
  .v-card__actions .v-btn__content {
    font-weight: bold;
  }
  .v-card .v-card__text {
    padding: 0;
  }
  .v-card__title.titleContent {
    color: $neutral-dark;
    font-weight: bolder;
    padding: 0;
    margin-bottom: 25px;
  }
  .dialog-confirm--actions {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    .v-btn {
      font-weight: bold;
    }
    &.left-actions {
      justify-content: flex-end;
      flex-direction: row-reverse;
      padding: 0;
      .v-btn.text-secondary {
        margin-left: 8px;
      }
    }
  }
  .dialog-confirm--modal_description {
    color: $neutral-medium;
    margin-bottom: 36px;
  }
}
.spaced-slot {
  margin-top: 40px;
}
</style>
